import { WithFields, WithIncludes } from "@/api";
import { Cdec as _Cdec } from "@/api/alarm";
import { useGetOne } from "@mb-pro-ui/utils";

const cdecFields = {
  "cdec-all": [
    "account",
    "active",
    "arrived",
    "color",
    "event",
    "floats",
    "event-code",
    "latitude",
    "localized-description",
    "longitude",
    "partition-number",
    "rfids",
    "sec-account",
    "sent",
    "texts",
    "text-msg",
    "unitid",
    "zone-number",
    "customer",
    "interval-rule",
    "rfid-token",
    "user",
    "partition",
    "zone",
  ],
  customers: [
    "id",
    "name",
    "address",
    "monitored-battery-level",
    "monitored-signal-strength",
  ],
  events: ["category"],
  "rfid-tokens": ["number", "place"],
  "interval-rules": ["number"],
  partitions: ["dsecription"],
  zones: ["place"],
  users: ["number", "name"],
} as const;

const cdecInclude = {
  customer: {},
  event: { category: {} },
  "interval-rule": {},
  "rfid-token": {},
  zone: {},
  user: {},
} as const;

export type Cdec = WithIncludes<
  WithFields<_Cdec, typeof cdecFields>,
  typeof cdecInclude
>;

export const useCdec = (cdecID: string) => {
  const cdec = useGetOne<Cdec>("alarm/cdec-all", cdecID, {
    fields: cdecFields,
    include: cdecInclude,
    enabled: !!cdecID,
  });

  return cdec;
};
