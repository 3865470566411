import { TypeCategories } from "@/api";
import { isJsonapiHttpError, useBatchedChanges } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { getErrorMessage } from "../../../hooks/useErrorHandler";
import { ModalFormProps } from "../types";

const useUseBatchedChanges = (
  category: TypeCategories,
  {
    refetch,
    setSnackbarState,
    onSuccess,
  }: Pick<ModalFormProps, "refetch" | "setSnackbarState" | "onSuccess">,
) => {
  const { formatMessage } = useIntl();

  return useBatchedChanges(category, {
    // onMutate: () => {
    //   setSnackbarState({
    //     message: formatMessage({ defaultMessage: "Save in progress" }),
    //   });
    // },
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully saved" }),
      });
      refetch?.();
      onSuccess?.();
    },
    onError: (err) => {
      setSnackbarState({
        message: isJsonapiHttpError(err)
          ? getErrorMessage(err)
          : formatMessage({ defaultMessage: "Failed to save" }),
        error: true,
      });
    },
    onMutate: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Request in progress" }),
      });
    },
  });
};

export default useUseBatchedChanges;
