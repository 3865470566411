import { WithFields, WithIncludes } from "@/api";
import { Category } from "@/api/alarm";
import { useGetAll, useStorageState } from "@mb-pro-ui/utils";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useMe from "../../hooks/useMe";
import { AudioEngine } from "./AudioEngine";

const fields = {
  categories: ["sound"],
  sounds: ["data"],
} as const;

const include = {
  sound: true,
} as const;

type PreloadInfo = WithIncludes<
  WithFields<Category, typeof fields>,
  typeof include
>;
type FilteredPreloadInfo = Omit<PreloadInfo, "sound"> & {
  sound: Exclude<PreloadInfo["sound"], null>;
};

export interface AudioPlayer {
  playOnce: (url: string) => void;
  startLoop: (url: string, ref: string) => void;
  stopLoops: (matchRef?: string, matchUrl?: string) => void;

  isLooping: boolean;
  isUnlocked?: boolean;
  allLoopRefs: Set<string>;

  volume: number;
  setVolume: (volume: number) => void;

  isNotificationOn: boolean;
  setIsNotificationOn: (isNotificationOn: boolean) => void;

  preloadAudio: (url: string) => void;
}

const AudioPlayerContext = React.createContext<AudioPlayer | undefined>(
  undefined,
);

function useAudio() {
  const audioPlayerContext = React.useContext(AudioPlayerContext);
  if (audioPlayerContext === undefined) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return audioPlayerContext;
}

function AudioPlayerProvider({ children }: PropsWithChildren<{}>) {
  const [volume, setVolume_] = useStorageState<number>("volume", 1);

  const setVolume = useCallback(
    (volume: number) => {
      setVolume_(Math.min(Math.max(volume, 0), 1));
    },
    [setVolume_],
  );

  const [isNotificationOn, setIsNotificationOn] = useStorageState<boolean>(
    "allowNotification",
    false,
  );

  const volumeRef = useRef(volume);

  const [isLooping, setIsLooping] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState<boolean>();
  const [allLoopRefs, setAllLoopRefs] = useState(() => new Set<string>());

  const audioEngineRef = useRef<AudioEngine | null>(null);

  const [preloadURLs] = useState(() => new Set<string>());

  useEffect(() => {
    volumeRef.current = volume;
    audioEngineRef.current?.setVolume(volume);
  }, [volume]);

  useEffect(() => {
    const audioEngine = (audioEngineRef.current = new AudioEngine(
      volumeRef.current,
    ));
    audioEngine.onIsLoopingChange = setIsLooping;
    audioEngine.onIsUnlockedChange = setIsUnlocked;
    audioEngine.onAllLoopRefsChange = setAllLoopRefs;
    setIsUnlocked(audioEngine.isUnlocked);
    audioEngine.tryUnlock();

    for (const url of preloadURLs) {
      audioEngine.preloadAudio(url).catch((e) => {
        console.error(e);
      });
    }

    return () => {
      audioEngine.tearDown();
      audioEngineRef.current = null;
    };
  }, [preloadURLs]);

  const handleStorageChange = useCallback((event: StorageEvent) => {
    if (event.key === "stop-alerts") {
      audioEngineRef.current?.stopLoops();
    }
  }, []);

  const tryUnlock = useCallback(() => {
    audioEngineRef.current?.tryUnlock();
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange, false);
    window.addEventListener("touchend", tryUnlock, false);
    window.addEventListener("click", tryUnlock, false);

    return () => {
      window.removeEventListener("storage", handleStorageChange, false);
      window.removeEventListener("touchend", tryUnlock, false);
      window.removeEventListener("click", tryUnlock, false);
    };
  }, [handleStorageChange, tryUnlock]);

  const playOnce = useCallback((url: string) => {
    audioEngineRef.current?.playOnce(url);
  }, []);

  const startLoop = useCallback((url: string, ref: string) => {
    audioEngineRef.current?.startLoop(url, ref);
  }, []);

  const stopLoops = useCallback((matchRef?: string, matchUrl?: string) => {
    audioEngineRef.current?.stopLoops(matchRef, matchUrl);
  }, []);

  const preloadAudio = useCallback(
    (url: string) => {
      if (audioEngineRef.current) {
        audioEngineRef.current.preloadAudio(url).catch((e) => {
          console.error(e);
        });
      }
      preloadURLs.add(url);
    },
    [preloadURLs],
  );

  const { data: me, isLoading: isMeLoading } = useMe();
  const alarmEnabled = !!me?.["alarm-operator"] && !isMeLoading;

  const { data } = useGetAll<FilteredPreloadInfo>("alarm/categories", {
    fields,
    include,
    filter: { sound: { null: "false" } },
    enabled: alarmEnabled,
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    for (const {
      sound: { data: url },
    } of data) {
      if (!url) {
        continue;
      }
      preloadAudio(url);
    }
  }, [data, preloadAudio]);

  return (
    <AudioPlayerContext.Provider
      value={useMemo(
        () => ({
          playOnce,
          startLoop,
          stopLoops,
          isLooping,
          isUnlocked,
          allLoopRefs,
          volume,
          setVolume,
          isNotificationOn,
          setIsNotificationOn,
          preloadAudio,
        }),
        [
          playOnce,
          startLoop,
          stopLoops,
          isLooping,
          isUnlocked,
          allLoopRefs,
          volume,
          setVolume,
          isNotificationOn,
          setIsNotificationOn,
          preloadAudio,
        ],
      )}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
}

export { AudioPlayerProvider, useAudio };
