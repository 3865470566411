import { WithFields } from "@/api";
import { useGetAll } from "@mb-pro-ui/utils";
import CalculateIcon from "@mui/icons-material/Calculate";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import { Badge, SvgIcon } from "@mui/material";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import useMe from "../../hooks/useMe";
import { LampRoutes } from "../types";
import { LampCounts } from "./types";

const fields = {
  "lamp-counts": ["needs-sync"],
} as const;

export type LampForGrouping = WithFields<LampCounts, typeof fields>;

const usePossibleLampConfigChanges = () => {
  const { data: allLamps } = useGetAll<LampForGrouping>("alarm/lamp-counts", {
    fields,
    refetchInterval: 60_000,
  });

  return allLamps?.some((v) => v["needs-sync"]);
};

const SyncLampConfigsIcon = () => {
  const anyChange = usePossibleLampConfigChanges();
  return (
    <Badge
      badgeContent={anyChange ? 1 : undefined}
      color={anyChange ? "warning" : undefined}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      overlap="circular"
      variant="dot"
    >
      <SvgIcon component={SyncIcon} />
    </Badge>
  );
};

const useLampMenuLinks = () => {
  const { formatMessage } = useIntl();

  // HACK: hardcoded feature flag to hide WIP features
  const me = useMe();
  const notMVM = !!me.data && me.data.username !== "mvm";

  return useMemo(
    () =>
      [
        {
          icon: <SvgIcon component={EmojiObjectsIcon} />,
          href: LampRoutes.Lamps,
          label: formatMessage({ defaultMessage: "Lamps" }),
        },
        {
          icon: <SvgIcon component={QueryStatsIcon} />,
          href: LampRoutes.Analitics,
          label: formatMessage({ defaultMessage: "Analitics" }),
        },
        {
          icon: <SvgIcon component={CalculateIcon} />,
          href: LampRoutes.Calculator,
          label: formatMessage({ defaultMessage: "Calculator" }),
        },
        {
          icon: <SvgIcon component={SettingsIcon} />,
          href: LampRoutes.Settings,
          label: formatMessage({ defaultMessage: "Settings" }),
        },
      ].concat(
        notMVM
          ? [
              {
                icon: <SyncLampConfigsIcon />,
                href: LampRoutes.Sync,
                label: formatMessage({ defaultMessage: "Sync" }),
              },
            ]
          : [],
      ),
    [formatMessage, notMVM],
  );
};

export default useLampMenuLinks;
