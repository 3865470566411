import { SmsConfig } from "@/api/alarm";
import { Select, TextField } from "@mb-pro-ui/components/form";
import { useGetAll } from "@mb-pro-ui/utils";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";

const ManualSmsFields = () => {
  const { formatMessage } = useIntl();
  const { data: configs } = useGetAll<SmsConfig>("alarm/sms-configs", {
    filter: { "allow-manual": { is: "true" } },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Select
        name="config"
        required
        items={(configs ?? []).map((c) => ({ value: c.id, label: c.name }))}
        renderOption={({ option }) => option.label}
        label={formatMessage({ defaultMessage: "SMS Config" })}
      />
      <TextField
        name="recipient"
        required
        label={formatMessage({ defaultMessage: "Phone" })}
        helperText={formatMessage({
          defaultMessage:
            "You can provide comma separated list of phone numbers",
        })}
        placeholder="+36202711141, 06302711141"
      />
      <TextField
        name="message"
        required
        multiline
        label={formatMessage({ defaultMessage: "Message" })}
      />
    </Box>
  );
};

export default ManualSmsFields;
