import { WithFields, WithIncludes } from "@/api";
import { Cdec as _Cdec } from "@/api/alarm";
import { useEvents, useGetAll } from "@mb-pro-ui/utils";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import useMe from "../../hooks/useMe";
import alert_arrived from "../../sound/alert_arrived.wav";
import { useAudio } from "../audio/AudioPlayerProvider";
import { onlyInFirst } from "../operator/interventionCdec/InterventionCdecWindow";

const fields = {
  cdec: ["localized-description", "customer", "category-sound"],
  customers: ["name", "account"],
} as const;

const include = {
  customer: true,
} as const;

type __Cdec = WithIncludes<WithFields<_Cdec, typeof fields>, typeof include>;

type Cdec = __Cdec & {
  customer: Exclude<__Cdec["customer"], null>;
};

type AlarmAlertsContextType = {
  count?: number;
  isError: boolean;
};

const AlarmAlertsContext = createContext<AlarmAlertsContextType | undefined>(
  undefined,
);

export const AlarmAlertsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data: me, isLoading: isMeLoading } = useMe();
  const interventionAllowed =
    !!me?.["alarm-operator"] &&
    !me["alarm-operator"]["read-only"] &&
    !isMeLoading;

  const playDing =
    (me?.["settings"]?.["alert-sound-type"] as unknown as "sound" | "ding") ===
    "ding";

  const audioEnabled = interventionAllowed;
  const snackbarEnabled = interventionAllowed;

  const location = useLocation();
  const isOperatorPage = !!matchPath(location.pathname, {
    path: "/alarm/operator",
  });

  const { startLoop, stopLoops, playOnce } = useAudio();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const playSound = (cdec: Cdec) => {
    if (cdec["category-sound"]) {
      startLoop(cdec["category-sound"], cdec.customer.id);
    } else {
      playOnce(alert_arrived);
    }
  };

  const { data, isError, refetch } = useGetAll<Cdec>("alarm/cdec", {
    page: { limit: 10000 },
    include,
    fields,
    filter: {
      "intervention-needed": { is: "true" },
      "category-alert": { is: "true" },
      customer: { null: "false" },
    },
    refetchInterval: interventionAllowed ? 20000 : undefined,
    refetchIntervalInBackground: interventionAllowed,
    sort: ["-id"],
    enabled: interventionAllowed,
    onSuccess: (successData) => {
      if (!data) {
        // first response
        return;
      }
      const newData = onlyInFirst(successData, data);
      const removedICdecs = onlyInFirst(data, successData);

      for (const cdec of newData) {
        if (audioEnabled) {
          try {
            if (isOperatorPage) {
              playSound(cdec);
            } else {
              if (playDing) {
                playOnce(alert_arrived);
              } else {
                playSound(cdec);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
        if (snackbarEnabled) {
          enqueueSnackbar(
            <Typography
              component={Link}
              to="/alarm/operator"
              sx={{
                whiteSpace: "pre",
                width: "300px",
                cursor: "pointer",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography variant="caption" sx={{ width: "100%" }} noWrap>
                {`${cdec.customer.name}(${cdec.customer.account}) \n`}
              </Typography>
              <Typography variant="subtitle2" sx={{ width: "100%" }} noWrap>
                {cdec["localized-description"]}
              </Typography>
            </Typography>,
            {
              variant: "error",
              autoHideDuration: 6000,
              disableWindowBlurListener: true, // disabled, because option not working as expected (disables timeout only for notifications present at blur)
              action: (key: string) => (
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => closeSnackbar(key)}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              ),
            },
          );
        }
      }

      for (const cdec of removedICdecs) {
        if (cdec["category-sound"]) {
          stopLoops(cdec.customer.id, cdec["category-sound"]);
        }
      }
    },
  });

  useEvents(["alert-insert", "alert-delete", "connect"], () => {
    interventionAllowed && refetch();
  });

  useEffect(() => {
    if (!snackbarEnabled) {
      closeSnackbar();
    }
  }, [closeSnackbar, snackbarEnabled]);

  return (
    <AlarmAlertsContext.Provider
      value={useMemo(
        () => ({
          count: data?.length,
          isError: isError,
        }),
        [data, isError],
      )}
    >
      {children}
    </AlarmAlertsContext.Provider>
  );
};

const useAlarmAlerts = () => {
  const value = useContext(AlarmAlertsContext);

  if (!value) {
    throw new Error("useAlarmAlerts must be used inside AlarmAlertsProvider");
  }
  return value;
};

export default useAlarmAlerts;
