import { Intervention } from "@/api/alarm";
import { useGetAll } from "@mb-pro-ui/utils";

const useInterventions = () => {
  return useGetAll<Intervention>("alarm/interventions", {
    include: {
      customer: true,
      session: { operator: true },
      "start-event": true,
    },
    fields: {
      interventions: ["start-time", "session", "start-event", "customer"],
      sessions: ["operator"],
      cdec: [
        "localized-description",
        "category-sound",
        "category-alert",
        "color",
        "active",
      ],
      customers: ["name", "account"],
      operators: ["login"],
    },
    page: {
      limit: 100,
    },
    filter: {
      "close-time": {
        null: "true",
      },
    },
    sort: ["-id"],
    refetchInterval: 10000,
  });
};

export default useInterventions;
