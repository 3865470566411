import SortIcon from "@mui/icons-material/Sort";
import {
  Box,
  IconButton,
  lighten,
  Menu,
  menuClasses,
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { HtmlTooltip } from "../../../intervention/SubtasksWidget";

// intervention.start-ts ~ MIN (cdec.arrived)

export type SortBy = "default" | "account" | "ts-asc" | "ts-desc";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: lighten(theme.palette.warning.light, 0.6),
  },
  [`&.${toggleButtonClasses.selected}:hover`]: {
    backgroundColor: lighten(theme.palette.warning.light, 0.4),
  },
}));

export const SortButton = ({
  sortBy,
  onSortByChange,
}: {
  sortBy: SortBy | null;
  onSortByChange: (value: SortBy | null) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { formatMessage } = useIntl();

  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSorted = (sortBy ?? "default") !== "default";

  return (
    <>
      <Tooltip title={formatMessage({ defaultMessage: "Sort interventions" })}>
        <IconButton
          onClick={handleClick}
          size="large"
          sx={[
            (theme) => ({
              color: theme.palette.common.white,
            }),
            // isSorted &&
            //   ((theme) => ({
            //     backgroundColor: alpha(
            //       lighten(theme.palette.warning.light, 0.4),
            //       0.6
            //     ),
            //     [`&:hover`]: {
            //       backgroundColor: lighten(theme.palette.warning.light, 0.4),
            //     },
            //     color: theme.palette.text.primary,
            //   })),
          ]}
        >
          <SortIcon
            color="inherit"
            sx={{
              color: (theme) =>
                isSorted
                  ? lighten(theme.palette.warning.light, 0.6)
                  : "inherit",
              // transition: "transform 0.2s",
              // transform: !isSorted
              //   ? "rotate(-90deg)"
              //   : sortBy === "account"
              //   ? "scale(-1, 1)"
              //   : sortBy === "ts-asc"
              //   ? "scale(1, -1)"
              //   : undefined,
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          [`& .${menuClasses.list}`]: { paddingTop: 0, paddingBottom: 0 },
        }}
      >
        <ToggleButtonGroup
          orientation="vertical"
          value={sortBy}
          exclusive
          onChange={(e, value) => onSortByChange(value)}
        >
          <Typography
            variant="subtitle2"
            padding={0.5}
            width="100%"
            bgcolor="divider"
            align="center"
          >
            {formatMessage({ defaultMessage: "Dynamic order" })}
          </Typography>

          <HtmlTooltip
            title={
              <Box display="block" p={1}>
                <Typography variant="body2" display="block">
                  {formatMessage({
                    defaultMessage:
                      "Prioritizes unhandled alerts, higher priority and older alerts.",
                  })}
                </Typography>
              </Box>
            }
            placement="left"
            arrow
          >
            <StyledToggleButton value="default" selected={sortBy === "default"}>
              {formatMessage({ defaultMessage: "Default" })}
            </StyledToggleButton>
          </HtmlTooltip>

          <HtmlTooltip
            title={
              <Box display="block" p={1}>
                <Typography
                  variant="body2"
                  display="block"
                  color="inherit"
                  bgcolor="inherit"
                >
                  {formatMessage({
                    defaultMessage:
                      "Sort by most recent alert arrival time, in descending order",
                  })}
                </Typography>
              </Box>
            }
            placement="left"
            arrow
          >
            <StyledToggleButton value="ts-desc" selected={sortBy === "ts-desc"}>
              {formatMessage({
                defaultMessage: "Last alert arrival (descending)",
              })}
            </StyledToggleButton>
          </HtmlTooltip>

          <HtmlTooltip
            title={
              <Box display="block" p={1}>
                <Typography variant="body2" display="block">
                  {formatMessage({
                    defaultMessage: "Sort by customer account, ascending order",
                  })}
                </Typography>
              </Box>
            }
            placement="left"
            arrow
          >
            <StyledToggleButton value="account" selected={sortBy === "account"}>
              {formatMessage({ defaultMessage: "Customer account" })}
            </StyledToggleButton>
          </HtmlTooltip>

          <Typography
            variant="subtitle2"
            padding={0.5}
            width="100%"
            bgcolor="divider"
            align="center"
          >
            {formatMessage({ defaultMessage: "Static order" })}
          </Typography>

          <HtmlTooltip
            title={
              <Box display="block" p={1}>
                <Typography variant="body2" display="block">
                  {formatMessage({
                    defaultMessage:
                      "Sort by earliest alert arrival time, in ascending order",
                  })}
                </Typography>
              </Box>
            }
            placement="left"
            arrow
          >
            <StyledToggleButton value="ts-asc" selected={sortBy === "ts-asc"}>
              {formatMessage({
                defaultMessage: "First alert arrival (ascending)",
              })}
            </StyledToggleButton>
          </HtmlTooltip>
        </ToggleButtonGroup>
      </Menu>
    </>
  );
};

export default SortButton;
