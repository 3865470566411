import { WithFields } from "@/api";
import { Customer as _Customer } from "@/api/alarm";
import { Button } from "@mb-pro-ui/components";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { LatLng } from "leaflet";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { serializeDateRange } from "../../table/filters/manual/base/DateRangeFilter";
import CoordsLabel from "../../utils/CoordsLabel";

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "inherit",
});
const fields = {
  customers: [
    "id",
    "account",
    "name",
    "address",
    "email",
    "phone",
    "mobile",
    "active",
  ],
} as const;

type Customer = WithFields<_Customer, typeof fields>;

type CustomerModalProps = {
  customer: Customer | null | undefined;
  open: boolean;
  onClose(): void;
};

export const CustomerModalContents = ({
  customer,
  latlng,
}: {
  customer?: Customer | null;
  latlng?: LatLng;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container>
      <Grid item xs={6}>
        <List dense>
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "Account",
                description: "Cdec felugró részletes nézet ügyfél (account)",
              })}
              secondary={customer?.account}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "Address",
                description: "Cdec felugró részletes nézet cím",
              })}
              secondary={customer?.address ? customer.address : "N/A"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatMessage({ defaultMessage: "Email" })}
              secondary={customer?.email ? customer.email : "N/A"}
            />
          </ListItem>
          {latlng && (
            <ListItem>
              <ListItemText
                primary={`${formatMessage({
                  defaultMessage: "Coordinates",
                })}${` (${formatMessage({
                  defaultMessage: "Longitude",
                })}, ${formatMessage({
                  defaultMessage: "Latitude",
                })})`}`}
                secondary={<CoordsLabel lat={latlng.lat} lon={latlng.lng} />}
              />
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={6}>
        <List dense>
          <ListItem>
            <ListItemText
              primary={formatMessage({ defaultMessage: "Phone" })}
              secondary={customer?.phone ? customer.phone : "N/A"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatMessage({ defaultMessage: "Mobile" })}
              secondary={customer?.mobile ? customer.mobile : "N/A"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondaryTypographyProps={{ component: "div" }}
              primary={formatMessage({
                defaultMessage: "Active",
                description:
                  "Intervention page customer data cutomer Active field",
              })}
              secondary={
                <ListItemIcon>
                  {customer?.active ? (
                    <CheckIcon fontSize="small" color="primary" />
                  ) : (
                    <ClearIcon fontSize="small" />
                  )}
                </ListItemIcon>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export const CustomerModalActions = ({
  customer,
}: {
  customer?: Pick<Customer, "id"> | null;
}) =>
  customer ? (
    <>
      <Button mode="secondary">
        <StyledLink
          to={`/alarm/events?customer=${
            customer.id
          }&arrived=${encodeURIComponent(
            serializeDateRange(moment().subtract(1, "days")),
          )}`}
        >
          <FormattedMessage
            defaultMessage="Customer events"
            description="Cdec felugró részletes nézet ügyfél eseményei"
          />
        </StyledLink>
      </Button>
      <Button>
        <StyledLink to={`/alarm/customers/${customer.id}`}>
          <FormattedMessage
            defaultMessage="Customer manager"
            description="Cdec felugró részletes nézet ügyfélkezelő"
          />
        </StyledLink>
      </Button>
    </>
  ) : (
    <Button>
      <StyledLink to="/alarm/customers/create">
        <FormattedMessage
          defaultMessage="Create customer"
          description="Cdec felugró részletes nézet ügyfél létrehozása"
        />
      </StyledLink>
    </Button>
  );

const CustomerModal = ({
  customer,
  open,
  onClose: handleClose,
}: CustomerModalProps) => (
  <Dialog disableScrollLock open={open} onClose={handleClose}>
    <DialogTitle
      sx={{
        padding: (theme) => theme.spacing(2),
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span
        style={{
          display: "inline-block",
          maxWidth: "70%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {customer?.name ?? (
          <FormattedMessage
            defaultMessage="Unknown customer"
            description="Cdec felugró részletes nézet ismeretlen ügyfél"
          />
        )}
      </span>
    </DialogTitle>
    <IconButton
      aria-label="close"
      sx={{
        position: "absolute",
        right: (theme) => theme.spacing(1),
        top: (theme) => theme.spacing(1),
        color: (theme) => theme.palette.common.white,
        "&:hover": {
          backgroundColor: "unset",
        },
      }}
      onClick={handleClose}
      disableRipple
      size="large"
    >
      <CloseIcon />
    </IconButton>
    <DialogContent sx={{ minWidth: "550px" }}>
      <CustomerModalContents customer={customer} />
    </DialogContent>
    <DialogActions>
      <CustomerModalActions customer={customer} />
    </DialogActions>
  </Dialog>
);

export default CustomerModal;
