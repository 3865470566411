import { ID, Type } from "@/api";
import { useDelete } from "@mb-pro-ui/utils";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { ModalFormProps } from "../types";

const useUseDelete = <T extends { type: string; id: ID }>(
  fulltype: Type<T["type"]>,
  {
    refetch,
    setSnackbarState,
    onSuccess,
    replaceTo,
  }: Partial<Pick<ModalFormProps, "refetch" | "onSuccess">> &
    Pick<ModalFormProps, "setSnackbarState"> & {
      replaceTo?: string;
    },
) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return useDelete(fulltype, {
    onSuccess: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Successfully delete" }),
      });
      refetch?.();
      onSuccess?.();
      replaceTo && history.replace(replaceTo);
    },
    onMutate: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Delete in progress" }),
        autoHideDuration: null,
      });
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Unsuccessful delete" }),
        error: true,
      });
    },
  });
};

export default useUseDelete;
