import { ID } from "@/api";
import { Button } from "@mb-pro-ui/components";
import { useApi } from "@mb-pro-ui/utils";
import { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import ManualSmsFields from "./ManualSmsFields";
import { Intervention } from "./types";

type ManualSmsModalProps = {
  onClose: () => void;
  intervention?: Intervention;
  handleQueryError: (error: JsonapiError) => void;
  handleQuerySuccess: () => void;
  initialValues?: Partial<ManualSms>;
};

type ManualSms = {
  config: ID;
  recipient: string;
  message: string;
  customer?: ID;
  billToCustomer?: boolean;
};

const ManualSmsModal = ({
  onClose,
  intervention,
  handleQueryError,
  handleQuerySuccess,
  initialValues,
}: ManualSmsModalProps) => {
  const { formatMessage } = useIntl();
  const api = useApi();

  const sendSms = useCallback(
    (data: ManualSms) => {
      return api("/actions/alarm/manual-sms", {
        method: "post",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(data),
      });
    },
    [api],
  );

  const { mutate: doSendSms } = useMutation(sendSms, {
    onError: handleQueryError,
    onSuccess: () => {
      handleQuerySuccess();
      onClose();
    },
  });

  const onSubmit = (values: ManualSms) => {
    doSendSms({
      ...values,
      customer: intervention?.customer?.id,
      billToCustomer: false,
    });
  };

  const [pristine, setPristine] = useState(true);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {formatMessage({ defaultMessage: "Manual SMS" })}
      </DialogTitle>
      <DialogContent dividers>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="manualSmsForm">
                <ManualSmsFields />
                <FormSpy
                  subscription={{ pristine: true }}
                  onChange={(props) => {
                    setPristine(props.pristine);
                  }}
                />
              </form>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" type="button" onClick={onClose}>
          {formatMessage({ defaultMessage: "Cancel" })}
        </Button>
        <Button type="submit" form="manualSmsForm" disabled={pristine}>
          {formatMessage({ defaultMessage: "Send" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualSmsModal;
