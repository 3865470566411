import { ID } from "@/api";
import { useCreate, useStorageState } from "@mb-pro-ui/utils";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FabProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  SpeedDial,
  SpeedDialAction,
  SpeedDialActionProps,
  Tooltip,
  darken,
  listItemTextClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import { LatLng } from "leaflet";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../../../hooks/useErrorHandler";
import useTimestampFormat from "../../../locales/useTimestampFormat";
import { SnackbarState } from "../../settings/types";
import { Snackbar } from "../../settings/utils";
import { serializeDateRange } from "../../table/filters/manual/base/DateRangeFilter";
import CoordsLabel from "../../utils/CoordsLabel";
import InactivateDialog from "./InactivateDialog";
import { Cdec, useCdec } from "./useCdec";

type CdecModalProps = {
  cdecID: string;
  onClose(): void;
  disableInactivate?: boolean;
};

type MbProFabProps<C extends React.ElementType> = Partial<
  FabProps<C, { component?: C }>
>;

type MbProSpeedDialActionProps = Omit<SpeedDialActionProps, "FabProps"> & {
  tooltipTitle?: SpeedDialActionProps["tooltipTitle"];
};

export const MbProSpeedDialAction = styled(
  <C extends React.ElementType>({
    className,
    ...props
  }: MbProSpeedDialActionProps & MbProFabProps<C> & { className?: string }) => {
    const { TooltipClasses, ...rest } = props;
    return (
      <SpeedDialAction
        {...rest}
        TooltipClasses={{ popper: className, ...TooltipClasses }}
      />
    );
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}));

const ModalListItemText = ({
  color,
  primaryTypographyProps: { sx: primarySx, ...primaryTypographyRestProps } = {},
  secondaryTypographyProps: {
    sx: secondarySx,
    ...secondaryTypographyRestProps
  } = {},
  ...props
}: ListItemTextProps<"span", "div"> & {
  color?: string;
}) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        component: "span",
        sx: [
          {
            [`&.${listItemTextClasses.root}, &.${listItemTextClasses.primary}`]:
              {
                color: color,
              },
          },
          ...(Array.isArray(primarySx) ? primarySx : [primarySx]),
        ],
        ...primaryTypographyRestProps,
      }}
      secondaryTypographyProps={{
        component: "div",
        sx: [
          { overflowWrap: "anywhere" },
          ...(Array.isArray(secondarySx) ? secondarySx : [secondarySx]),
        ],
        ...secondaryTypographyRestProps,
      }}
      {...props}
    />
  );
};

export const CdecModalContents = ({
  cdec,
  disableInactivate,
  latlng,
}: {
  cdec: Cdec;
  disableInactivate?: boolean;
  latlng?: LatLng;
}) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();
  const color = cdec.color ?? cdec.event?.category.hex;
  const customer = cdec.customer;
  const customerID = typeof customer === "string" ? customer : customer?.id;

  const getEventCategory = (cdec: Cdec): string => {
    switch (cdec.event?.category.id) {
      case "14": //fallthrough
      case "15":
        return cdec.user?.name
          ? formatMessage({ defaultMessage: "User" }) + ": " + cdec.user?.name
          : formatMessage({ defaultMessage: "User" });
      case "29":
        return cdec.customer?.["monitored-signal-strength"]
          ? formatMessage({ defaultMessage: "signal strength" })
          : "";
      case "31":
        return cdec["rfid-token"]?.place
          ? formatMessage({ defaultMessage: "RFID token" }) +
              ": " +
              cdec["rfid-token"]?.place
          : formatMessage({ defaultMessage: "RFID token" });
      case "32":
        return formatMessage({ defaultMessage: "Interval rule" });
      default:
        return cdec.zone?.place
          ? `${formatMessage({ defaultMessage: "Zone" })}: ${cdec.zone.place}`
          : "null";
    }
  };

  const customerDialActions = [
    {
      name: formatMessage({ defaultMessage: "Edit customer" }),
      href: `/alarm/customers/${customerID}`,
    },
    {
      name: formatMessage({ defaultMessage: "Customer events" }),
      href: `/alarm/events?customer=${customerID}&arrived=${encodeURIComponent(
        serializeDateRange(moment().subtract(1, "days")),
      )}`,
    },
  ];

  const coords = latlng
    ? { lat: latlng.lat, lon: latlng.lng }
    : cdec.latitude && cdec.longitude
      ? { lat: cdec.latitude, lon: cdec.longitude }
      : null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "600px",
      }}
    >
      <Box sx={{ flexDirection: "row", display: "flex", flex: 1 }}>
        <Box sx={{ display: "flex", flex: 1, width: "300px" }}>
          <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
            <ListItem>
              <ModalListItemText
                color={color ?? cdec.color}
                primary={formatMessage({
                  defaultMessage: "Event code",
                  description: "Cdec felugró részletes nézet eseméykód",
                })}
                secondary={
                  <Inactivate
                    cdec={cdec}
                    disableInactivate={disableInactivate}
                  />
                }
                secondaryTypographyProps={{}}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {`${formatMessage({ defaultMessage: "Customer" })}`}

                    {(cdec.customer?.name || cdec.customer?.address) && (
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          cdec.customer?.name ? (
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box>{`${formatMessage({ defaultMessage: "Name" })}: ${cdec.customer?.name ?? "N/A"}`}</Box>
                              <Box>{`${formatMessage({ defaultMessage: "Address" })}: ${cdec.customer?.address ?? "N/A"}`}</Box>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                      >
                        <InfoIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                      </Tooltip>
                    )}
                  </Box>
                }
                secondaryTypographyProps={{
                  component: "div",
                  width: "200%",
                }}
                secondary={
                  customer ? (
                    <SpeedDial
                      ariaLabel="Customer speed dial"
                      FabProps={{
                        size: "small",
                        variant: "extended",
                        sx: {
                          backgroundColor: color ?? cdec.color,
                          "&:hover": {
                            backgroundColor: darken(color, 0.2),
                          },
                        },
                      }}
                      icon={cdec.account}
                      direction="right"
                    >
                      {cdec.customer
                        ? customerDialActions.map((action, i) => (
                            <MbProSpeedDialAction
                              FabProps={{
                                size: "small",
                                variant: "extended",
                                sx: { color, zIndex: 1 },
                                component: Link,
                                to: action.href,
                              }}
                              icon={action.name}
                              key={i}
                            />
                          ))
                        : null}
                    </SpeedDial>
                  ) : (
                    `${cdec.account} - ${formatMessage({ defaultMessage: "Unknown customer" })}`
                  )
                }
              />
            </ListItem>

            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Helios ID" })}
                secondary={cdec.unitid ?? "N/A"}
              />
            </ListItem>

            <ListItem>
              <ModalListItemText
                color={color}
                primary={`${formatMessage({
                  defaultMessage: "Coordinates",
                })}${
                  coords
                    ? ` (${formatMessage({
                        defaultMessage: "Longitude",
                      })}, ${formatMessage({
                        defaultMessage: "Latitude",
                      })})`
                    : ""
                }`}
                secondary={coords ? <CoordsLabel {...coords} /> : "N/A"}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ display: "flex", flex: 1, width: "300px" }}>
          <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({
                  defaultMessage: "Event time",
                  description: "Cdec felugró részletes nézet küldve ekkor",
                })}
                secondary={formatTimestamp(cdec.sent) ?? "N/A"}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({
                  defaultMessage: "Arrival time",
                  description: "Cdec felugró részletes nézet érkezés",
                })}
                secondary={formatTimestamp(cdec.arrived) ?? "N/A"}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Partition number" })}
                secondary={
                  cdec.event?.category.id === "29" &&
                  cdec.customer?.["monitored-battery-level"]
                    ? `${cdec["partition-number"]} - ${formatMessage({ defaultMessage: "battery level" })}`
                    : cdec["partition-number"]
                }
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {formatMessage({ defaultMessage: "Zone number" })}
                    {getEventCategory(cdec) !== "null" ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title={getEventCategory(cdec)}
                      >
                        <InfoIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                      </Tooltip>
                    ) : null}
                  </Box>
                }
                secondary={cdec["zone-number"]}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Active" })}
                secondary={
                  <ListItemIcon>
                    {cdec.active ? (
                      <CheckIcon fontSize="small" color="primary" />
                    ) : (
                      <ClearIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Box>
      {customer ? (
        <ListItem sx={{ display: "flex", paddingTop: 0 }} dense>
          <ModalListItemText
            color={color}
            primary={formatMessage({ defaultMessage: "Event description" })}
            secondary={cdec["localized-description"] ?? "N/A"}
          />
        </ListItem>
      ) : null}
    </Box>
  );
};

const ExtendedCdecModalContent = ({ cdec }: { cdec: Cdec }) => {
  const { formatMessage } = useIntl();
  const color = cdec.color;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CdecModalContents cdec={cdec} />
      <Box sx={{ display: "flex", maxWidth: "300px" }}>
        <Box sx={{ display: "flex" }}>
          <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Text message" })}
                secondary={cdec["text-msg"] ?? "\xA0"}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({
                  defaultMessage: "Secondary customer account",
                })}
                secondary={cdec["sec-account"] ?? "N/A"}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export const Inactivate = ({
  cdec,
  disableInactivate,
}: {
  cdec: Cdec;
  disableInactivate?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { account, "event-code": eventCode } = cdec;
  const color = cdec.color ?? cdec.event?.category.hex;

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();

  const {
    mutate: disableEvent,
    status,
    error,
  } = useCreate("alarm/disabled-events");

  useEffect(() => {
    if (status === "success") {
      setSnackbarState({
        message: formatMessage(
          {
            defaultMessage:
              "{account} customer's {eventCode} events inactivated",
            description: "Event inactivation success",
          },
          {
            account,
            eventCode,
          },
        ),
      });
    } else if (status === "error") {
      setSnackbarState({
        message: error
          ? getErrorMessage(error)
          : formatMessage({
              defaultMessage: "Something went wrong",
              description: "Error handler default error message",
            }),
        error: true,
      });
    }
  }, [status, account, eventCode, error, formatMessage]);

  const label = formatMessage({
    defaultMessage: "Disable event",
    description: "Cdec felugró részletes nézet inaktiválás",
  });

  return (
    <>
      <SpeedDial
        ariaLabel="Event code speed dial"
        FabProps={{
          size: "small",
          variant: "extended",
          sx: {
            backgroundColor: color,
            "&:hover": {
              backgroundColor: darken(color, 0.2),
            },
            zIndex: 1,
          },
        }}
        icon={cdec["event-code"]}
        direction="right"
      >
        <MbProSpeedDialAction
          FabProps={{
            size: "small",
            variant: "extended",
            sx: { color, zIndex: 1 },
          }}
          disabled={!cdec.customer || !cdec.active || disableInactivate}
          onClick={() => setIsAlertOpen(true)}
          icon={label}
        />
      </SpeedDial>

      {cdec.customer !== null ? (
        <InactivateDialog
          open={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          customer={cdec.customer}
          account={cdec.account}
          eventCode={cdec["event-code"]}
          onInactivate={(customer: ID, eventCode: string) => {
            disableEvent({ customer, "event-code": eventCode });
            setIsAlertOpen(false);
          }}
        />
      ) : null}

      <Snackbar
        onClose={() => setSnackbarState({ message: undefined })}
        state={snackbarState}
      />
    </>
  );
};

type ViewState = "standard" | "extended";

const defaultViewState: { $version: number; view: ViewState } = {
  $version: 1.0,
  view: "standard",
};

function parseViewState(json: string) {
  const result = JSON.parse(json) as typeof defaultViewState;
  if (result.$version === defaultViewState.$version) {
    return result;
  } else {
    return defaultViewState;
  }
}

const CdecModal = ({ cdecID, onClose, disableInactivate }: CdecModalProps) => {
  const { data: cdecAll } = useCdec(cdecID);
  const { formatMessage } = useIntl();
  const [customizedViewState, setCustomizedViewState] = useStorageState(
    "eventModalView",
    defaultViewState,
    false,
    parseViewState,
  );

  const handleViewChange = () => {
    setCustomizedViewState(
      customizedViewState.view === "standard"
        ? { ...customizedViewState, view: "extended" }
        : { ...customizedViewState, view: "standard" },
    );
  };

  return (
    <Dialog disableScrollLock open onClose={onClose} maxWidth={false}>
      {!!cdecAll && (
        <>
          <DialogTitle
            sx={{
              backgroundColor: cdecAll.color ?? cdecAll.event?.category.hex,
              padding: (theme) => theme.spacing(2),
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              maxWidth:
                customizedViewState.view === "standard"
                  ? `${600 + 48}px`
                  : undefined,
            }}
            component={"div"}
          >
            <Box component={"span"} sx={{ width: "80px" }}></Box>
            <Box
              component={"span"}
              flexGrow={1}
              flexShrink={1}
              flexBasis={1}
              position="relative"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {cdecAll.event?.category["localized-description"] ||
                `${formatMessage({ defaultMessage: "Unknown event" })} ${cdecAll["event-code"]}`}
            </Box>
            <Box
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                flexGrow: 0,
                display: "flex",
                width: "80px",
              }}
              component={"span"}
            >
              <Tooltip
                title={
                  customizedViewState.view === "standard"
                    ? formatMessage({ defaultMessage: "Extended view" })
                    : formatMessage({ defaultMessage: "Standard view" })
                }
              >
                <span>
                  <IconButton onClick={handleViewChange}>
                    {customizedViewState.view === "standard" ? (
                      <FullscreenIcon />
                    ) : (
                      <CloseFullscreenIcon />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={formatMessage({ defaultMessage: "Close" })}>
                <span>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex" }}>
              {customizedViewState.view === "standard" && (
                <CdecModalContents
                  cdec={cdecAll}
                  disableInactivate={!!disableInactivate}
                />
              )}

              {customizedViewState.view === "extended" && (
                <ExtendedCdecModalContent cdec={cdecAll} />
              )}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CdecModal;
