import { ID } from "@/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

type InactivateDialogProps = {
  open: boolean;
  onClose: () => void;
  customer: ID | { id: ID };
  account: string;
  eventCode: string;
  onInactivate: (customerId: ID, eventCode: string) => void;
};

export const InactivateDialog = ({
  open,
  onClose: handleClose,
  customer,
  account,
  eventCode,
  onInactivate: handleInactivate,
}: InactivateDialogProps) => {
  const handleInactivateButtonClick = () => {
    handleInactivate(
      typeof customer === "string" ? customer : customer.id,
      eventCode,
    );
  };

  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={handleClose} disableScrollLock>
      <DialogContent>
        <DialogContentText>
          {formatMessage(
            {
              defaultMessage:
                "Are you sure you want to inactivate {account} customer's {eventCode} events? You can re-activate events in the customer manager.",
              description: "Inactivate event popup message",
            },
            { account, eventCode },
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage
            defaultMessage="Cancel"
            description="Esemény inaktiválása felugró 'mégse' gomb"
          />
        </Button>
        <Button onClick={handleInactivateButtonClick} color="primary" autoFocus>
          <FormattedMessage
            defaultMessage="Yes"
            description="Esemény inaktiválása felugró 'igen' gomb"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InactivateDialog;
