import moment, { MomentInput } from "moment";
import React, { ForwardedRef, useEffect, useReducer } from "react";
import { IntlShape, useIntl } from "react-intl";

export function formatDuration(
  duration: moment.Duration,
  formatMessage: IntlShape["formatMessage"],
  zero?: string,
) {
  const days = duration.days();
  const weeks = duration.weeks();
  const months = duration.months();

  const time = `${format(duration.hours())}:${format(
    duration.minutes(),
  )}:${format(duration.seconds())}`;

  return months >= 1
    ? formatMessage(
        {
          defaultMessage:
            "{months, number} {months, plural, one {month} other {months}}",
        },
        { months },
      )
    : weeks >= 1
      ? formatMessage(
          {
            defaultMessage:
              "{weeks, number} {weeks, plural, one {week} other {weeks}}",
          },
          { weeks },
        )
      : days >= 1
        ? formatMessage(
            {
              defaultMessage:
                "{days, number} {days, plural, one {day} other {days}}",
            },
            { days },
          )
        : time === "00:00:00"
          ? zero ?? time
          : time;
}

const Timer24h = React.forwardRef(
  (
    { startTime, ...rest }: { startTime: MomentInput },
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    const reRender = useReducer((x) => x + 1, 0)[1];
    const duration = moment.duration(moment().diff(moment(startTime)));
    const days = duration.days();

    useEffect(() => {
      if (days === 0) {
        const timer = setInterval(() => {
          reRender();
        }, 1000);
        return () => clearInterval(timer);
      } else {
        const timer = setInterval(
          () => {
            reRender();
          },
          1000 * 60 * 5,
        );
        return () => clearInterval(timer);
      }
    }, [days, reRender]);

    const { formatMessage } = useIntl();

    return (
      <span ref={ref} {...rest}>
        {formatDuration(duration, formatMessage, "--:--:--")}
      </span>
    );
  },
);

function format(time: number) {
  if (time.toString().length < 2) {
    return `0${time}`;
  }
  return time;
}

export default Timer24h;
