import { WithFields } from "@/api";
import { Customer } from "@/api/alarm";
import { Button, EmptyPlaceholder } from "@mb-pro-ui/components";
import { useGetOne } from "@mb-pro-ui/utils";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-final-form";
import { FormattedMessage } from "react-intl";

const fields = { customers: ["related-email-addresses"] } as const;

type CustomerWithContacts = WithFields<Customer, typeof fields>;

const EmailInsertButton = ({
  customer,
}: {
  customer?: string | null | { id: string };
}) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>();
  const open = Boolean(anchor);
  const handleClose = () => {
    setAnchor(null);
  };

  const { change, getState } = useForm();

  const addContact = (email: string, name: string | null) => {
    const to = getState().values?.to ?? "";
    const contact = name ? `${name} <${email}>` : email;

    change("to", to ? `${to}, ${contact}` : contact);
  };

  const customerID =
    typeof customer === "string" ? customer : customer?.id ?? null;

  const { data: customerWithContacts } = useGetOne<CustomerWithContacts>(
    "alarm/customers",
    customerID ?? "<disabled>",
    {
      enabled: !!customerID && open,
      fields: fields,
    },
  );

  const {
    show,
    customerEmail,
    notifiableEmails,
    installerEmails,
    guardEmails,
    authorityEmails,
  } = useMemo(() => {
    const contacts = customerWithContacts?.["related-email-addresses"] ?? [];
    const show = contacts.length > 0;

    const customerEmail = contacts.filter(
      (c) => c["contact-type"] === "customers",
    );
    const notifiableEmails = contacts.filter(
      (c) => c["contact-type"] === "notifiable-persons",
    );
    const installerEmails = contacts.filter(
      (c) => c["contact-type"] === "installers",
    );
    const guardEmails = contacts.filter((c) => c["contact-type"] === "guards");
    const authorityEmails = contacts.filter(
      (c) => c["contact-type"] === "authorities",
    );

    return {
      show,
      customerEmail,
      notifiableEmails,
      installerEmails,
      guardEmails,
      authorityEmails,
    };
  }, [customerWithContacts]);

  const renderSubList = (
    title: React.ReactNode,
    list: Exclude<CustomerWithContacts["related-email-addresses"], null>,
  ) => (
    <>
      {list.length > 0 && (
        <ListSubheader
          sx={{ lineHeight: "unset", pl: 1, color: "text.primary" }}
        >
          {title}
        </ListSubheader>
      )}
      {list.map((c) => {
        const emails = c.email.split(/[,; |]/g).filter(Boolean);
        return emails.map((email) => {
          return (
            <MenuItem
              onClick={() => addContact(email, c.name)}
              key={c["contact-id"] + email}
            >
              <ListItemText primary={c.name} secondary={email} />
            </MenuItem>
          );
        });
      })}
    </>
  );

  return (
    <>
      <Button
        sx={{ justifySelf: "center", minWidth: "max-content" }}
        endIcon={<AddIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchor(event.currentTarget);
        }}
      >
        <FormattedMessage defaultMessage="Insert email address" />
      </Button>
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: 350,
          },
        }}
      >
        <MenuList dense>
          {show ? (
            <Box>
              {renderSubList(
                <FormattedMessage defaultMessage="Customer" />,
                customerEmail,
              )}
              {renderSubList(
                <FormattedMessage defaultMessage="Notifiable persons" />,
                notifiableEmails,
              )}
              {renderSubList(
                <FormattedMessage defaultMessage="Installers" />,
                installerEmails,
              )}
              {renderSubList(
                <FormattedMessage defaultMessage="Guards" />,
                guardEmails,
              )}
              {renderSubList(
                <FormattedMessage defaultMessage="Authorities" />,
                authorityEmails,
              )}
            </Box>
          ) : (
            <EmptyPlaceholder
              sx={{ p: 2 }}
              message={
                <FormattedMessage defaultMessage="No available emails" />
              }
            />
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default EmailInsertButton;
